const firebaseConfig = {
  projectId: "rijk-s-village",
  appId: "1:1078271502179:web:e710eb9ed474e03ee2d556",
  databaseURL:
    "https://rijk-s-village-default-rtdb.europe-west1.firebasedatabase.app",
  storageBucket: "rijk-s-village.appspot.com",
  locationId: "europe-west",
  apiKey: "AIzaSyDpus7ERPc7iDOYk1hTpn5ulUFodAu0dRc",
  authDomain: "rijk-s-village.firebaseapp.com",
  messagingSenderId: "1078271502179",
  measurementId: "G-RVX5QR7KJZ",
};

export default firebaseConfig;
